import { styled, css } from "common/utils/stitches.config";
import Head from "next/head";
import Image from "next/legacy/image";

export default function QHeader() {
  return (
    <>
      <Head>
        <title>Aset Detail | QRID</title>
      </Head>
      <HeaderContainer>
        <div className={styles.fullLogo()}>
          <a href="/">
            <Image src="/QRID.svg" alt="qrid" height="36" width="100" />
          </a>
        </div>
      </HeaderContainer>
    </>
  );
}

const InnerHeaderContainer = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "100%",
  margin: "0 auto",
  "@xs": {
    maxWidth: 490,
  },
  "@sm": {
    maxWidth: 690,
  },
  "@md": {
    maxWidth: 740,
    justifyContent: "flex-start",
    gap: 32,
  },
  "@lg": {
    maxWidth: 900,
    // pt: "$lg",
  },
  "@xl": {
    maxWidth: 1140,
    // gap: 64,
  },
});
const HeaderContainer = styled("div", {
  // position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  px: "$lg",
  gap: 36,
  color: "black",
  background: "white",
  height: 80,
  position: "sticky",
  top: 0,
  // boxShadow: "0px 2px 18px 3px rgb(0 0 0 / 30%)",
  // backdropFilter: "saturate(180%) blur(5px)",
  // backgroundColor: "hsla(0,0%,100%,0.82)",
  zIndex: 1100,
  borderBottom: "1px solid #E0E0E0",

  defaultVariants: {
    scrolled: "inactive",
  },

  "> header": {
    position: "relative",
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px auto",
    maxWidth: "90%",
    "@lg": {
      maxWidth: "95%",
    },
  },
});

const styles = {
  logoLink: css({
    display: "flex",
    alignItems: "center",
  }),
  link: css({
    position: "relative",
    textDecoration: "none",
    color: "black",
    userSelect: "none",
    "&::after": {
      position: "absolute",
      display: "block",
      content: "",
      height: 4,
      width: 24,
      background: "black",
      borderRadius: 16,
      mx: "auto",
      left: 0,
      right: 0,
      bottom: 4,
      transition: "0.3s",
      transform: "scaleX(0)",
    },
    "@md": {
      "&:hover": {
        "&::after": {
          transform: "scaleX(1)",
        },
        // borderBottom: "2px solid black",
      },
      "&.active": {
        // borderBottom: "2px solid black !important",
        "&::after": {
          transform: "scaleX(1)",
        },
      },
    },
  }),
  mobileLinkContainer: css({
    width: "80%",
    "> a div": {
      borderTop: "1px solid #CED0D1",
    },
    "> a:first-child div": {
      borderTop: "none",
    },
  }),
  mobileLink: css({
    textDecoration: "none",
    color: "black",
    userSelect: "none",
    width: "80%",
    // borderTop: "1px solid #CED0D1",
    // "&:focus": {
    //   background: "red",
    //   opacity: 0.85,
    //   // borderBottom: "2px solid white",
    // },
    "&.active": {
      // borderBottom: "2px solid black",
      // width: 100,
      // color: "$primary",
      color: "$blue",
      // fontWeight: "bold",
    },
  }),
  fullLogo: css({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  }),
  hamburger: css({
    zIndex: 999,
    cursor: "pointer",
    "&.active": {
      "& span": {
        "&:nth-child(2)": {
          opacity: 0,
        },
        "&:nth-child(1)": {
          transform: "translateY(8px) rotate(45deg)",
        },
        "&:nth-child(3)": {
          transform: "translateY(-8px) rotate(-45deg)",
        },
      },
    },
    "@md": {
      display: "none",
    },
  }),
  active: css({}),
  bar: css({
    color: "black",
    display: "block",
    width: 25,
    height: 3,
    margin: "5px auto",
    "-webkit-transition": "all 0.3s ease-in-out",
    transition: "all 0.3s ease-in-out",
    backgroundColor: "black",
  }),
  burgerMenu: css({
    color: "$primary",
    mr: 16,
    "@lg": {
      display: "none",
    },
  }),

  insideDashboard: css({
    "@lg": {
      display: "none",
    },
  }),
  outsideDashboard: css({
    display: "flex",
    flexDirection: "column",
  }),
};

const A = styled("a", {
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  cursor: "pointer",
  color: "white",

  // "&:hover": {
  //   color: "$primary",
  // },
});

const NavContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  height: "100%",
  position: "relative",
});
const MobileNavContainer = styled("div", {
  display: "none",
  "@md": {
    // position: "absolute",
    left: "auto",
    top: "auto",
    // backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    mr: 10,
  },
});

const MobileContainer = styled("div", {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "$secondary",
  top: 0,
  width: "100%",
  pt: 32,
  pb: "$lg",
  border: "1px solid #EFF5F8",
  borderBottomLeftRadius: 32,
  borderBottomRightRadius: 32,
  boxShadow: "0px 2px 18px 3px rgb(0 0 0 / 30%)",

  transition: "0.5s",
  left: 0,
  // : opacity 300ms ease-in, visibility 0s ease-in 300ms;
  // transition: "opacity 300ms ease-in, visibility 0s ease-in 300ms",
  "&.inactive": {
    zIndex: -100,
    // display: "none",
    opacity: 0,
    // top: "-100%",
    // bottom: "-100%",
    top: "-100vh",
  },
  // display: "none",

  // boxShadow: "0 10px 27px rgba(0, 0, 0, 0.05)",
  "&.active": {
    opacity: 1,
    zIndex: 100,
    top: 0,
    // left: 0,
  },
  "@md": {
    display: "none",
  },
});

const itemStyles = {
  // textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  padding: "20px 16px",
  // borderTop: "1px solid #636465",
  // mt: 16,
  cursor: "pointer",
  // "&:focus": {
  //   opacity: 0.85,
  // },
  "&:focus": {
    outline: "none",
    opacity: 0.85,
    // backgroundColor: "$primary",
    color: "black",
  },
  "@md": {
    mt: 0,
    alignItems: "center",
    display: "flex",
    padding: "10px 16px",
    // borderRadius: 3,
    cursor: "pointer",
    "&:focus": {
      outline: "none",
      // backgroundColor: "$primary",
      color: "black",
    },
  },
};
