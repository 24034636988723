import QHeader from "components/widgets/q-header";
import { store } from "models";
import * as React from "react";
import * as Redux from "react-redux";

import PublicLayout from "./public-layout";

interface DefaultLayoutProps {
  children: React.ReactNode;
  pageProps: any;
}

export default function DefaultLayout(props: DefaultLayoutProps) {
  return (
    <Redux.Provider store={store}>
      <div>
        <main style={{ display: "flex", flexDirection: "column" }}>
          <QHeader />
          <PublicLayout>{props.children}</PublicLayout>
        </main>
      </div>
    </Redux.Provider>
  );
}
