import { GenderType } from "./user-gender.enum";
import { ShopLite } from "../shop/shop.model";
import { ItemLite } from "../item/item.model";

export enum AccountDefaultType {
  regular = 'regular',
  vendor = 'vendor'
}

export interface UserLite {
  id: string;
  name: string;
  email: string;
  image?: string;
  imageUrl?: string;
  role?: string;
  phone: string;
  createdAt: string;
  updatedAt: string;
  accessItems: ItemLite[];
}

export interface UserAuth {
  id: string;
  name: string;
  phone: string;
  createdAt: string;
  updatedAt: string;
  passwordPin: boolean;
}
export interface UserAddress {
  id: string;
  label: string;
  street: string;
  region: {
    id: string;
    label: string;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
}
export interface User {
  id: string;
  name: string;
  email: string;
  image: string;
  imageUrl: string;
  unreadNotificationCount: number;
  nextServiceReminder: number;
  createdAt: string;
  updatedAt: string;
  address: UserAddress;
  phone: string;
  shops: ShopLite[];
  gender: string;
  dateOfBirth: string;
  userItemsCount: number;
  filled: boolean;
  accountDefault: string;
  passwordPin: boolean;
}

export interface EditProfileInput {
  name: string;
  email?: string;
  address?: {
    street?: string;
    regionId?: string;
  };
  dateOfBirth?: string;
  gender?: GenderType | string;
}
export interface UpdateProfileImageInput {
  image: string;
}

export interface AccountDeletionInput {
  password: string;
}

export interface UserBookIndex {
  id: string;
  owner: UserLite;
  target?: UserLite;
  targetShop?: ShopLite;
  color: string;
  city: string;
  position: string;
}

export interface getUserBookIndexInput {
  params?: {
    limit?: number;
    q?: string;
  };
}

export interface UserUpdateInput {
  userId: string;
  body: {
    name: string;
    image?: string;
    email?: string;
    address?: {
      street?: string;
      regionId?: string;
    };
  };
}

export interface UserChangePasswordInput {
  password: string;
  passwordConfirmation: string;
}

export interface UpdateAccountDefaultType {
  shop_id: string;
  type: AccountDefaultType | string;
}

export interface UpdateAccountVendorSetting {
  is_vendor: boolean;
}
