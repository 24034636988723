import { setupToken } from "common/repositories";
import { authSelector } from "models/auth";
import * as React from "react";
import { useSelector } from "react-redux";

interface Props {
  children: React.ReactNode;
}
export default function PublicLayout(props: Props) {
  const [isTokenSet, setIsTokenSet] = React.useState(false);
  const credentials = useSelector(authSelector.credentials);
  React.useEffect(() => {
    async function exec() {
      if (credentials) {
        await setupToken(credentials.accessToken);
      }
      setIsTokenSet(true);
    }

    exec();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isTokenSet ? <>{props.children} </> : null;
}
