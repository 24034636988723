const routes = {
  home: "/user",
  profile: "/user/profile",
  asset: "/user/asset",
  history: "/user/history",
  team: "/user/team",
  notification: "/user/notification",
  consumer: "/user/consumer",
  preset: "/user/preset",
  location: "/user/location",
  book: "/user/book",
  reminder: "/user/reminder",
  report: "/user/report",
  serviceTemplate: "/user/service-template",
  presetDetails: (id) => `/user/preset/${id}`,
  bookPage: (page, keyword) =>
    `/user/book?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  serviceTemplatePage: (page, keyword) =>
    `/user/servicetemplate?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  presetPage: (page, keyword) =>
    `/user/preset?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  consumerPage: (page, keyword) =>
    `/user/consumer?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  locationPage: (page, keyword) =>
    `/user/location?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  vendor: "/user/vendor",
  vendorPage: (page, keyword) =>
    `/user/vendor?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  consumerDetail: (id) => `/user/consumer/${id}`,
  addConsumer: "consumer/add-consumer",
  setting: "/user/setting",
  itemDetail: (id) => `/user/asset/${id}`,
  ItemAddService: (id) => `/user/asset/${id}/add-service`,
  ItemAddServiceLogbook: (id) => `/user/asset/${id}/add-service/logbook`,
  ItemSelectPresetAddService: (id) =>
    `/user/asset/${id}/add-service/select-preset`,
  ItemEditService: (id, editServiceId) => `/user/asset/${id}/${editServiceId}`,
  itemServiceHistory: (id) => `/user/asset/${id}/history`,
  historyDetail: (id) => `/user/history/${id}`,
  historyPage: (page, keyword) =>
    `/user/history?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  assetPage: (page, keyword) =>
    `/user/asset?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  teamPage: (page, keyword) =>
    `/user/team?page=${page}${keyword ? `&keyword=${keyword}` : ""}`,
  itemServicePending: (id) => `/user/asset/${id}/service-pending`,
  informationGainPage: (id, action) =>
    `/user/asset/${id}/gain?action=${action}`,
  addTeamPage: "/user/team/add-team",
  informationLocationPage: (id, action) =>
    `/user/asset/${id}/location?action=${action}`,
  informationConsumerPage: (id, action) =>
    `/user/asset/${id}/consumer?action=${action}`,
  servicePage: (id, action) => `/user/asset/${id}/service?action=${action}`,
  stockServicePage: (id, serviceId, action, userServiceId) =>
    `/user/asset/${id}/stock-service?serviceId=${serviceId}&action=${action}${
      userServiceId ? `&userServiceId=${userServiceId}` : ""
    }`,
  assetListPage: "/user/asset/asset-list",
  assetTemplateListPage: (itemId, title) =>
    `/user/asset/asset-list/asset-template-list/${itemId}?title=${title}`,
  addAssetPage: (itemId, title, templateId) =>
    `/user/asset/asset-list/add-asset/${itemId}${
      templateId ? `/${templateId}` : ""
    }?title=${title}`,
  importAssetPage: `/user/asset/asset-list/import-asset`,

  notificationSettingPage: "/user/setting/notification-setting",
  userAuthorityPage: "/user/setting/user-authority",
  tutorialMenuPage: "/user/setting/tutorial-menu",
  tutorialDetailPage: (tutorialId) =>
    `/user/setting/tutorial-menu/tutorial-detail/${tutorialId}`,
  consumerSettingPage: "/user/setting/consumer-setting",
  consumerDetailPage: (consumerId) =>
    `/user/setting/consumer-setting/consumer-detail/${consumerId}`,
  addConsumerPage: "/user/setting/consumer-setting/add-consumer",
  editConsumerPage: (consumerId) =>
    `/user/setting/consumer-setting/consumer-detail/${consumerId}/edit-consumer`,
  locationSettingPage: "/user/setting/location-setting",
  locationDetailPage: (type, locationName) =>
    `/user/setting/location-setting/location-detail/${type}/${locationName}`,
  changePinPage: "/user/setting/change-pin",
  reportDownloadPage: "/user/setting/report-download",
  assetTypePage: "/user/setting/report-download/asset-type",
  choosePeriodPage: (type, itemId) =>
    `/user/setting/report-download/choose-period?type=${type}${
      itemId ? `&itemId=${itemId}` : ""
    }`,
  changePhoneNumberPage: "/user/setting/change-phone-number",
  deleteAccountPage: "/user/setting/delete-account",
  confirmationDeleteAccount:
    "/user/setting/delete-account/confirmation-delete-account",
  addVendor: "/user/vendor/add-vendor",
  editVendor: (id) => `/user/vendor/${id}`,
};

export default routes;
