export default {
  consumer: "User",
  add_consumer: "Add User",
  location: "Lokasi",
  add_location: "Add Lokasi",
  warranty: "Warranty",
  add_warranty: "Add Warranty",
  purchase: "Purchase",
  add_purchase: "Add Purchase Info",
  active: "Active",
  expired: "Expired",
  move: "Pindah",
  renter: "Penyewa",
  rent: "Sewa",
  cost: "Biaya",
  completed: "Selesai",
  extend: "Extend",
  transfer: "Transfer",
  user: "Pemakai",
  add_user: "Add Pemakai",
  position: "Jabatan",
  division: "Divisi",
  room: "Ruangan",
  floor: "Lantai",
  building: "Building",
  area: "Area",
  sub: "Cabang",
  city: "Kota",
  gain: "Perolehan",
  add_gain: "Add Perolehan",
  edit_gain: "Edit Perolehan",
  tambah_gain: "Tambah Data Perolehan",
  delete_consumer_confirmation:
    "Apakah anda yakin ingin menghapus pemakai ini?",
  delete_rent_confirmation: "Apakah anda yakin ingin menghapus sewa ini?",
  delete_location_confirmation: "Apakah anda yakin ingin menghapus lokasi ini?",
  delete_gain_confirmation:
    "Apakah anda yakin ingin menghapus data perolehan ini?",
  use: "Pakai",
  edit: "Edit",
  neccesity: "Keperluan",
  delete_information_confirmation:
    "Apakah anda yakin ingin menghapus data {{type}} ini?",
  select_preset: "Pilih Preset",
  add_service: "Catat Servis",
  delete_service: "Hapus Servis",
  phone_input_placeholder: "Contoh 82276543478",
  add_note: "Tambah Catatan",
  set_user: "Set Pemakai",
  set_rent: "Set Penyewa",
  set_gain: "Set Perolehan",
  edit_service: "Edit Servis",
};
